@mixin block-display {
    display: inline-block;
    content: "";
}

@function invertValues($top, $right, $bottom, $left) {
    @if $dir == right {
        @return $top $left $bottom $right;
    }
    @else {
        @return $top $right $bottom $left;
    }
}

@each $name, $color in $color-map {
    .bg-#{$name} {
        background-color: $color !important;
    }
    .btn-#{$name} {
        background-color: $color !important;
        border-color: $color;
        &:hover, &:focus, &:active {
            background: rgba($color, 0.8);
        }
    }
    .text-#{$name} {
        color: $color !important;
    }
}
  
@each $name, $color in $color-map {
    @for $i from 1 through 9 {
        .bg-#{$name}-#{$i} {
            background-color: $color;
        }
    }
}

// @include size(20px, 20px);
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin hoverAnim() {
    transition: all 300ms;
}