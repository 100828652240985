.header {
    position: absolute;
    padding: 15px 0px;
    z-index: 20;
    width: 100%;
    left: 0;
    top: 0;

    @include media-breakpoint-down(md) {  
        padding-inline: 0;
    }
    &.header-white  {
        position: relative;
        .header-menu {
            ul {
                li {
                    a {
                        color: $black-color;
                    }
                }  
            } 
        } 
        .lang {
            color: $black-color;
        }
    }
    .header-logo {
        img {
            width: 180px;
            @include media-breakpoint-down(lg) {  
                width: 140px;
            }
            @include media-breakpoint-down(sm){  
                width: 115px;
            }
        }
    }
    .header-menu {
        @include media-breakpoint-down(lg) {  
            position: absolute;
            right: 0;
            top: 100%;
            width: 100%;
            background: $primary-color;
            display: none;
        }
        ul {
            display: flex;
            align-items: center;
            @include media-breakpoint-down(lg) {  
                display: block;
                text-align: right;
                padding: 20px 0; 
                html[dir="rtl"] & {
                    text-align: $start-direction;
                }
            }
            li {
                position: relative;
                margin-inline-end: 35px;
                transition: all 200ms;
                @include media-breakpoint-down(lg) {  
                    html[dir="rtl"] & {
                        margin-inline-end: 0px;
                    }
                }
                a {
                    font-size: 18px;
                    color: $white-color;
                    font-family:$font-stack-4;
                    line-height: 1.2;
                    position: relative;
                    html[dir="rtl"] &{
                        font-family: $font-stack-6;   
                    } 
                    &:before, &:after {
                        position: absolute;
                        left: 0px;
                        width: 100%;
                        height: 2px;
                        background: $primary-color;
                        content: "";
                        opacity: 0;
                        transition: all 0.4s;
                    }
                    &:before {
                        top: -16px;
                        transform: translateY(10px);
                    }
                    &:hover {
                        &:before{
                            top: 16px;
                            background: $primary-color;
                            opacity: 1;
                          }
                    } 
                }
            }
        }
    }
    .lang {
        display: inline-block;
        margin: 0 15px;
        position: relative;
        padding-inline-start: 30px;
        color: $white-color;
        background: transparent;
        border: 0px solid;
        html[dir="rtl"] & {
            padding-inline-start: 0px;   
        }
        &:before {
            position: absolute;
            background-image: url(/assets/images/flag-ar.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;
            border: 1px solid #86828d;
            content: "";
            display: block;
            left: 0;
            top: 4px;
            width: 24px;
            height: 18px;
        }
        html[dir="rtl"] & {
            &:before {
                background-image: url(/assets/images/flag-en.svg);  
                margin-inline-end: -30px;
            }
        }
        span {
            vertical-align: middle;  
        }
    }
    #hamburgermenu {
        @include media-breakpoint-down(lg) {  
            order: 2;
            margin-inline-start: 20px;
         } 
    }
        .hamburger {
            display: none;
            cursor: pointer;
            position: relative;
            margin-inline-start:20px;
            z-index: 100;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            border: 2px solid $primary-color;
            align-items: center;
            justify-content: center;
            transition: 0.4s;
            background-color: $primary-color; 
            @include media-breakpoint-down(sm) {  
                margin-inline-start:0px;
            }
            & .bar1, .bar2, .bar3{
                width: 4px;
                height: 4px;
                border-radius: 50px;
                background-color: #ffffff;
                margin: 0 2px;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                display: block;
            }
            @include media-breakpoint-down(lg) {
               display: flex;
            } 
        }
    }
.menu-open {
    .header {
        .header-menu {
         display: block;   
         ul {
            li {
                padding: 5px 20px;
                margin-right: 0;   
            }
         }
        }
        .hamburger {
            flex-direction: column;
            transform: rotate(-180deg);
            .bar1, .bar3 {
                width: 20px;
                height: 2px;
                margin: 7px 0;
                border-radius: 0;
            }
            .bar1 {
                transform: rotate(-45deg) translate(-7px, 7px);
            }
            .bar2 {
               opacity: 0;
            }
            .bar3 {
                transform: rotate(45deg) translate(-7px, -7px);
            }
        }
    } 
}