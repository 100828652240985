@font-face {
    font-family: 'avenir-regular';
    src: url('../../fonts/avenir-regular-webfont.eot');
    src: url('../../fonts/avenir-regular-webfont.svg') format('svg'),
         url('../../fonts/avenir-regular-webfont.woff') format('woff'),
         url('../../fonts/avenir-regular-webfont.ttf') format('truetype'),
         url('../../fonts/avenir-regular-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'avenir-medium';
    src: url('../../fonts/avenir-medium-webfont.eot');
    src: url('../../fonts/avenir-medium-webfont.svg') format('svg'),
         url('../../fonts/avenir-medium-webfont.woff') format('woff'),
         url('../../fonts/avenir-medium-webfont.ttf') format('truetype'),
         url('../../fonts/avenir-medium-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'avenir-heavy';
    src: url('../../fonts/avenir-heavy-webfont.eot');
    src: url('../../fonts/avenir-heavy-webfont.svg') format('svg'),
         url('../../fonts/avenir-heavy-webfont.woff') format('woff'),
         url('../../fonts/avenir-heavy-webfont.ttf') format('truetype'),
         url('../../fonts/avenir-heavy-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'avenir-book';
    src: url('../../fonts/avenir-book-webfont.eot');
    src: url('../../fonts/avenir-book-webfont.svg') format('svg'),
         url('../../fonts/avenir-book-webfont.woff') format('woff'),
         url('../../fonts/avenir-book-webfont.ttf') format('truetype'),
         url('../../fonts/avenir-book-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'avenir-black';
    src: url('../../fonts/avenir-black-webfont.eot');
    src: url('../../fonts/avenir-black-webfont.svg') format('svg'),
         url('../../fonts/avenir-black-webfont.woff') format('woff'),
         url('../../fonts/avenir-black-webfont.ttf') format('truetype'),
         url('../../fonts/avenir-black-webfont.woff2') format('woff2');
}

@font-face {
    font-family: 'itc_handel_gothic_arabic_w23hv';
    src: url('../../fonts/itc_handel_gothic_arabic_w23hv-webfont.eot');
    src: url('../../fonts/itc_handel_gothic_arabic_w23hv-webfont.svg') format('svg'),
         url('../../fonts/itc_handel_gothic_arabic_w23hv-webfont.woff') format('woff'),
         url('../../fonts/itc_handel_gothic_arabic_w23hv-webfont.ttf') format('truetype'),
         url('../../fonts/itc_handel_gothic_arabic_w23hv-webfont.woff2') format('woff2');
}
