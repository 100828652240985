.contact-us-sec  {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    color: $white-color;
    @include media-breakpoint-down(lg){  
        height: 400px;
    }
    @include media-breakpoint-down(md){  
        height: 320px;
    }
    h3 {
        font-family: $font-stack-3;
        color: $white-color;  
        html[dir="rtl"] &{
            font-family: $font-stack-6;   
        } 
    }
}
.get-in-touch-wrap {
    display: flex;
    align-items: center;
    min-height: 100vh;
    .contact-us-heading {
        h3 {
            font-family: $font-stack-3;        
            line-height: 48px;
            font-size: 46px;
            @include media-breakpoint-down(md){  
                font-size: 35px;
                font-size: 33px;
            }
            html[dir="rtl"] &{
                font-family: $font-stack-6;   
            } 
        }
        p{
            font-size: 20px;
            font-family: $font-stack-4;      
            @include media-breakpoint-down(md){  
                font-size: 16px;
            }
            html[dir="rtl"] &{
                font-family: $font-stack-6;   
            } 
        }
    }
    .contact-form {
        .form-label {
            font-family: $font-stack-2;   
            html[dir="rtl"] &{
                font-family: $font-stack-6;   
            } 
        }
        input {
            &.form-control {
                border-bottom: 1px solid rgb(86 87 89 / 35%)!important;
                border-radius: 0px;
                border: none;
                font-size: 15px;
                padding-inline-start: 0px;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}
// map section
.map-holder {
    height: 600px;
    @include media-breakpoint-down(md){  
     height: 500px;
    }
    .address-overlay {
        background-color: rgb(210 210 210 / 83%);
        color:$secondary-blue;
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: 0;
        inset-inline-end: 0;
        padding: 20px;
        h5 {
            font-family: $font-stack-3;
            padding-block-end: 10px;
            html[dir="rtl"] &{
                font-family: $font-stack-6;   
            } 
        }
        p {
            a {
                font-family: $font-stack-4;
                color:$secondary-blue;
                text-decoration: underline;  
                html[dir="rtl"] &{
                    font-family: $font-stack-6;   
                } 
            }
        }
    }
}