// Who we are section
.sec-content {

    // padding-inline-start: 60px;
    h4 {
        font-size: 19px;
        font-family: $font-stack-4;

        @include media-breakpoint-down(md) {
            font-size: 17px;
        }

        html[dir="rtl"] & {
            font-family: $font-stack-6;
        }
    }

    p {
        font-family: $font-stack-4;
        font-size: 15px;

        html[dir="rtl"] & {
            font-family: $font-stack-6;
        }
    }
}

.brandInfo-img {
    position: relative;

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    &>img {
        width: 260px;

        @include media-breakpoint-down(lg) {
            width: 220px;
        }

        &.floating {
            animation-name: floating;
            animation-duration: 2.5s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }

        @keyframes floating {
            from {
                transform: translate(0, 0px);
            }

            65% {
                transform: translate(0, 5px);
            }

            to {
                transform: translate(0, -0px);
            }
        }
    }

    .rocketImg {
        margin-inline-start: -60px;
        margin-block-start: -15px;
        width: 95px;

        @include media-breakpoint-down(lg) {
            margin-inline-start: -50px;
        }
    }
}


// What we do section
.services-wrapper {
    .service-wrap {
        position: relative;
        height: 100%;

        &:before,
        &:after {
            content: "";
            width: 2px;
            height: 0;
            position: absolute;
            transition: all 0.2s linear;
            background: $primary-color;
        }

        &:before {
            inset-inline-start: 0px;
            bottom: 0;
            transition-duration: 0.4s;
        }

        &:after {
            inset-inline-end: 0px;
            inset-block-start: 0;
            transition-duration: 0.4s;
        }

    }

    .service-holder {
        padding: 20px;
        padding-block: 30px;
        background-color: rgb(171 184 195 / 6%);
        // border-bottom: 2px solid $white-color;
        height: 100%;
        position: relative;
        transition: all .6s ease;

        &:after,
        &:before {
            content: "";
            width: 0;
            height: 2px;
            position: absolute;
            transition: all 0.2s linear;
            background: $primary-color;
        }

        &:after {
            left: 0px;
            bottom: 0;
            transition-duration: 0.4s;
        }

        &:before {
            right: 0px;
            top: 0;
            transition-duration: 0.4s;
        }

        img {
            padding-block-end: 35px;
        }

        h4 {
            font-family: $font-stack-3;
            font-size: 22px;

            @media (max-width:1600px) {
                font-size: 20px;
            }

            html[dir="rtl"] & {
                font-family: $font-stack-6;
            }
        }

        p {
            font-family: $font-stack-4;
            font-size: 18px;
            line-height: 23px;
            margin-block-end: 0;

            @media (max-width:1600px) {
                font-size: 16px;
            }

            html[dir="rtl"] & {
                font-family: $font-stack-6;
            }
        }
    }

    >.row .col-lg-3:hover {
        &:nth-child(1) {
            .service-wrap {
                img {
                    &.rotate-in-up-left {
                        animation: rotate-in-up-left 1s ease;
                    }

                    @keyframes rotate-in-up-left {
                        0% {
                            transform-origin: left bottom;
                            transform: rotate(15deg);
                            //   opacity: 0;
                        }

                        100% {
                            transform-origin: left bottom;
                            transform: rotate(0);
                            //   opacity: 1;
                        }

                    }
                }
            }
        }

        &:nth-child(2) {
            .service-wrap {
                img {
                    &.swing {
                        transform-origin: top right;
                        animation: swing 1s ease;
                    }

                    @keyframes swing {
                        0% {
                            transform: rotate(15deg);
                        }

                        // 40% { transform: rotate(-10deg); }
                        // 60% { transform: rotate(5deg); }	
                        // 80% { transform: rotate(-5deg); }	
                        100% {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            .service-wrap {
                img {
                    &.wobble {
                        animation: wobble 1s ease;
                    }

                    @keyframes wobble {
                        0% {
                            transform: translateX(0%);
                        }

                        15% {
                            transform: translateX(-3px);
                        }

                        100% {
                            transform: translateX(0%);
                        }
                    }
                }
            }
        }

        &:nth-child(4) {
            .service-wrap {
                img {
                    &.shake {
                        animation: shake 1s ease;
                    }

                    @keyframes shake {

                        0%,
                        20%,
                        50%,
                        80%,
                        100% {
                            transform: translateY(0);
                        }

                        40% {
                            transform: translate(-1px, 1px);
                        }

                        60% {
                            transform: translate(-1px, 1px);
                        }
                    }
                }
            }
        }

        &:nth-child(5) {
            .service-wrap {
                img {
                    &.rotate-in-up-left2 {
                        animation: rotate-in-up-left2 1s ease;
                    }

                    @keyframes rotate-in-up-left2 {
                        0% {
                            transform-origin: left bottom;
                            transform: translateX(5px);
                            //   transform: rotate(2deg);
                        }

                        50% {
                            transform: rotate(2deg);
                        }

                        100% {
                            transform-origin: left bottom;
                            transform: rotate(0);
                        }
                    }
                }
            }
        }

        &:nth-child(6) {
            .service-wrap {
                img {
                    &.shake2 {
                        animation: shake2 1s ease;
                    }

                    @keyframes shake2 {

                        0%,
                        20%,
                        50%,
                        80%,
                        100% {
                            transform: translateX(0);
                        }

                        40% {
                            transform: translateX(-1px);
                        }

                        60% {
                            transform: translateX(-1px);
                        }
                    }
                }
            }
        }

        &:nth-child(7) {
            .service-wrap {
                img {
                    &.gelatine {
                        animation: gelatine 1s;
                    }

                    @keyframes gelatine {

                        from,
                        to {
                            transform: scale(1, 1);
                        }

                        25% {
                            transform: scale(0.95, 1.05);
                        }

                        50% {
                            transform: scale(1.05, 0.95);
                        }

                        // 75% { transform: scale(0.95, 1.05); }
                    }
                }
            }
        }

        .service-wrap {
            .service-holder {
                // box-shadow: 0px 0px 15px 0px #05163140;
                border-bottom: none;

                &:after,
                &:before {
                    width: 100%;
                }
            }

            &:after,
            &:before {
                height: 100%;
            }
        }
    }
}

.box {
    @media (min-width:570px) {
        margin-bottom: 5rem;
    }

    @media (min-width:760px) {
        margin-bottom: 3rem;
    }

    @media (min-width:1500px) {
        margin-bottom: 5rem;
    }
}

#WeDo {
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
}

.mobile-float-img {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    overflow: hidden;
    margin-inline-end: -98px;
    margin-block-start: -216px;

    @media (max-width:1600px) {
        margin-top: -175px;
    }

    @include media-breakpoint-down(sm) {
        position: relative;
        margin-block-start: 0px;
    }

    img {
        margin-inline-end: -100px;

        @media (max-width:1600px) {
            width: 650px;
        }

        @include media-breakpoint-down(sm) {
            margin-inline-end: 0px;
        }
    }

    &>div img:nth-child(1) {
        margin-inline-end: -30px;

        @include media-breakpoint-down(xxl) {
            width: 470px;
        }

        @include media-breakpoint-down(sm) {
            width: 400px;
        }
    }

    &>div img:nth-child(2) {
        position: absolute;
        right: 260px;
        top: -100px;

        @include media-breakpoint-down(xxl) {
            position: absolute;
            right: 135px;
            top: -130px;
        }

        @include media-breakpoint-down(sm) {
            width: 90px;
            right: 120px;
            top: -82px;
        }
    }
}

#OurClient {
    @media (min-width:570px) {
        padding-top: 1rem !important;

        .row {
            margin-top: 5rem !important;
        }
    }

    @media (min-width:600px) {
        .row {
            margin-top: 6rem !important;
        }
    }

    @media (min-width:1230px) {
        .row {
            margin-top: 0rem !important;
        }
    }

    @media (min-width:1370px) {
        padding-top: 0rem !important;

        .row {
            margin-top: 0rem !important;
        }
    }

}

// Our works section
.works-wrapper {
    margin-top: 3rem !important;

    @media (min-width: 1500px) {
        margin-top: 6rem !important;
    }

    .work-icon {
        padding-block-end: 45px;
        max-height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;

        @include media-breakpoint-down(lg) {
            padding-block-end: 15px;
        }

        img {
            height: 100%;
            filter: grayscale(1);
            max-width: 300px;
            // @include media-breakpoint-down(xxl){  
            //     width: 260px;
            // }
        }

        &:hover {
            img {
                filter: grayscale(0);
            }
        }
    }

    >.row {
        >.col-auto {
            &:nth-of-type(24) {
                .work-icon {
                    img {
                        filter: contrast(0);
                    }

                    &:hover {
                        img {
                            filter: contrast(1);
                        }
                    }
                }
            }
        }

    }
}

#OurWork {
    margin-block-start: 80px;

    @include media-breakpoint-down(xxl) {
        margin-block-start: 40px;
    }
}