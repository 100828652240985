.slider-outer {
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        width: 15%;
        height: 100%;
        position: absolute;
        left: -285px;
        top: 0;
        box-shadow: 0px 0px 255px 160px rgb(255 255 255 / 92%);
        z-index: 1;
    }

    &:after {
        content: "";
        width: 15%;
        height: 100%;
        position: absolute;
        right: -285px;
        top: 0;
        box-shadow: 0px 0px 255px 160px rgb(255 255 255 / 92%);
    }
}

.work-slider {
    .slick-list {
        overflow: visible;
    }

    .slick-slide:nth-child(odd) {
        >div {
            .item {
                top: 50px;

                @include media-breakpoint-down(sm) {
                    top: 0px;
                }
            }
        }
    }

    .slick-slide:nth-child(even) {
        >div {
            .item {
                top: -50px;

                @include media-breakpoint-down(sm) {
                    top: 0px;
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #c7c7c7;
        top: 60%;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            top: 50%;
            width: 40px;
            height: 40px;
        }

        &:before,
        &:after {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: #c7c7c7;

            &:before,
            &:after {
                background-color: $primary-color;
            }
        }
    }

    .slick-next {
        right: 30px;

        html[dir="rtl"] & {
            left: 30px;
        }

        &:before {
            content: '';
            -webkit-mask-image: url(/assets/images/next.svg);
            -webkit-mask-position: center center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            display: inline-block;
            background-color: $black-color;
            width: 25px;
            height: 25px;
            opacity: 1;
            color: transparent;

            @include media-breakpoint-down(xl) {
                width: 20px;
                height: 20px;
            }

            html[dir="rtl"] & {
                transform: rotate(-180deg);
            }
        }
    }

    .slick-prev {

        html[dir="rtl"] & {
            right: 30px;
        }

        html[dir="ltr"] & {
            inset-inline-start: 30px;
        }

        &:before {
            content: '';
            -webkit-mask-image: url(/assets/images/prev.svg);
            -webkit-mask-position: center center;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            display: inline-block;
            background-color: $black-color;
            width: 25px;
            height: 25px;
            opacity: 1;
            color: transparent;

            @include media-breakpoint-down(xl) {
                width: 20px;
                height: 20px;
            }

            html[dir="rtl"] & {
                transform: rotate(180deg);
            }
        }
    }

    .item {
        position: relative;
        margin: 0 5px;
        height: 680px;
        overflow: hidden;
        // transition: all 1s;
        @media (max-width:1600px) {
            height: 520px;
        }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(14, 14, 14, 0), rgb(0 0 0 / 68%), rgb(0 0 0 / 94%));
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: all 0.5s;
        }

        .item-overlay {
            position: absolute;
            top: 0;
            bottom: 0px;
            left: 0px;
            z-index: 1;
            width: 100%;
            height: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tag {
                font-size: 12px;
                padding-block: 5px;
                padding-inline: 13px;
                color: #ffffff;
                border-radius: 4px;
                width: fit-content;
            }

            .item-details {
                margin-block-start: auto;

                h5 {
                    font-family: $font-stack-2;
                    color: $white-color;
                    padding-block-end: 20px;

                    @include media-breakpoint-down(lg) {
                        padding-block-end: 10px;
                    }
                    html[dir="rtl"] &{
                        font-family: $font-stack-6;   
                    } 
                }

                .next-prev-btn {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }
            }
        }
        &:hover {
            & img {
                transform: scale(1.1);
                transition: all 0.5s;
            }
        }
    }

    .container-lg {
        >.row .col-4 {
            &:nth-child(odd) {
                .item {
                    margin-top: -100px;
                }
            }
        }
    }

}