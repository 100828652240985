.banner {
    height: 100vh;
    position: relative;
    @include media-breakpoint-down(xxl){  
        height: 80vh;
    }
    @include media-breakpoint-down(lg){  
        height: 60vh;
    }
    .video-holder {
        height: 100%;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover; 
        }
    }
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgb(0 0 0 / 60%);
    }
    .hero-text {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
    }
    .scrollDown {
        position: absolute;
        bottom: 25px;
        z-index: 1;
        left: 46%;
        > :nth-child(1){
            html[dir="rtl"] &{
                width: 70px;  
                }
            }
      > :nth-child(2){
        width: 50px;
      }  
      @include media-breakpoint-down(lg){  
        left: 43%;
    }
    @include media-breakpoint-down(sm){  
        left: 40%;
    }
    }
}