.sec-padding {
    padding-block: 60px;

    @include media-breakpoint-down(md) {
        padding-block: 30px;
    }

    &.slider-outer {
        @include media-breakpoint-down(md) {
            padding-block: 60px;
        }

        @include media-breakpoint-down(sm) {
            padding-block: 0px;
        }
    }
}

// Page not found
.not-found-holder {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    background-color: #051631;
    color: $white-color;
    text-align: center;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    h3 {
        color: $primary-color;
        font-size: 120px;
        margin-block-end: 65px;
        display: inline-block;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            font-size: 80px;
            margin-block-end: 40px;
        }
    }

    h5 {
        font-size: 30px;
        margin-block-end: 30px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            font-size: 25px;
            margin-block-end: 20px;
        }
    }

    p {
        font-size: 25px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    img {
        &.spin {
            animation: spin 3s linear infinite;
        }

        @keyframes spin {
            0% {
                opacity: 0;
                transform: translateY(-400px);
            }

            10% {
                opacity: 1;
                transform: translateY(0);
            }

            20% {
                transform: rotate(15deg);
            }

            40% {
                transform: rotate(-10deg);
            }

            60% {
                transform: rotate(5deg);
            }

            80% {
                transform: rotate(-5deg);
            }

            90% {
                transform: rotate(0deg);
            }

            100% {
                transform: translateY(200px);
                opacity: 0;
            }

        }

    }
}

//Page loader
.loader-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    z-index: 99999;
    text-align: center;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #00000061;

    img {
        &.grow {
            animation: grow 1.5s ease infinite;
        }

        @keyframes grow {

            from,
            to {
                transform: scale(1, 1);
            }

            25% {
                transform: scale(0.9, 1.1);
            }

            50% {
                transform: scale(1.1, 0.9);
            }

            75% {
                transform: scale(0.95, 1.05);
            }
        }
    }
}