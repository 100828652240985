.btn {
    @include hoverAnim();
    &:hover, &:focus {
      outline: none;
      box-shadow: none;
      @include hoverAnim();
    }
    &.hamburger-btn{
        padding: 0;
        border: none;
    }
    &.primary-btn {
      color: $white-color;
      border: 1px solid $white-color;
      border-radius: 25px;
      padding: 6px 28px;
      &:hover {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
    &.snd-btn {
      &:hover {
        color: $secondary-color;
        background-color: transparent !important;
        border: 1px solid $secondary-color;
      }
    }
    &.contact-us-btn {
      &:hover {
        color: $primary-color;
        background-color: $white-color;
      } 
    }
}