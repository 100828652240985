// font  
$font-stack-1:'avenir-regular';   
$font-stack-2:'avenir-medium';     
$font-stack-3:'avenir-heavy'; 
$font-stack-4:'avenir-book'; 
$font-stack-5:'avenir-black'; 
$font-stack-6:'itc_handel_gothic_arabic_w23hv'; 

// Colors
$primary-color: #EC4320; 
$secondary-color: #051631;
$grey-color:#F8F8F8;
$grey-transparent:#efefef80;
$secondary-blue:#14294A;

$white-color: #ffffff;
$black-color: #000000;

$color-map: (
	primary-color: #EC4320,
	secondary-color: #051631,
	white-color: #ffffff,
	black-color: #000000,
	grey-color:#F8F8F8,
	grey-transparent:#efefef80,
	secondary-blue:#14294A,
);