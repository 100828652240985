// Video modal begins
.video-modal {

}
.popup-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ReactModal__Overlay--after-open {
    background-color: rgb(0 0 0 / 75%)!important;
    z-index: 1!important;

}
.ReactModal__Content--after-open {
    overflow: hidden !important;
    padding-block-start: 35px !important;
    // border: none !important;; 
    // background: transparent !important;
    @include media-breakpoint-down(md){  
      width: 90% !important; 
    }
    &.common-modal {
        position: absolute;
        inset: 50% auto auto 50%;
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        &.video-modal{
            // width: 65%;
            height: 95%;
            background-color: transparent!important;
            z-index: 1 !important;
        }
        &.mailsent-modal{
            width: 35%;
            height: 35%;
            border: 1px solid rgb(204, 204, 204)!important;
            background: rgb(255, 255, 255)!important;
        }
    }
}
.btn-close {
    background-size: 13px;
    position: absolute;
    .video-modal &{
      right: 15px;
      top: 0px;  
      opacity: 1;
      background-image: url(/assets/images/close.png);
    }
    .mailsent-modal &{
      right: 5px;
      top: 5px;
    }
}
// Mail sent modal
.mailsentModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img {
        position: relative;
        width: 130px;
    }
    $color-green: #00AD6B;
    $circle-size: 56px; 
    .circle-holder {
        position: absolute;
        top: 20px;
        right: 35px;
        & .circle,
        & .circle-border {
          width: $circle-size;
          height: $circle-size;
          border-radius: 50%;
        }
        .circle {
          z-index: 1;
          position: relative;
          background: white;
          transform: scale(1);
          animation: success-anim 700ms ease;
        }
        .circle-border {
          z-index: 0;
          position: absolute;
          background: $color-green;
          transform: scale(1.1);
          animation: circle-anim 400ms ease;
        }
        
        @keyframes success-anim {
          0% {
            transform: scale(0);
          }
          30% {
            transform: scale(0);
          }
          100% {
            transform: scale(1);
          }
        }
        
        @keyframes circle-anim {
          from {
            transform: scale(0);
          }
          to {
            transform: scale(1.1);
          }
        }
        
        .success::before,
        .success::after {
          content: "";
          display: block;
          height: 4px;
          background: $color-green;
          position: absolute;
        }
        
        .success::before {
          width: 17px;
          top: 58%;
          left: 23%;
          transform: rotateZ(50deg);
        }
        
        .success::after {
          width: 30px;
          top: 50%;
          left: 35%;
          transform: rotateZ(-50deg);
        } 
    }
}