html {
    scroll-behavior: smooth; 
}

body, html{
    font-family: $font-stack-1;     
    font-weight: normal;
    direction: $direction;
    text-align: $start-direction;
    font-size: 16px;
    background: $white-color;
    color: $secondary-color;
	min-height: 100%;
	&[dir="rtl"] {
        font-family: $font-stack-6;   
		direction: $opposite-direction;
        text-align: $end-direction;
	}
}
body {
    html[dir="rtl"] &{
        font-family: $font-stack-6;   
		direction: $opposite-direction;
        text-align: $end-direction;
	}  
}
ol, ul, li, figure {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}
@media (min-width:1600px)
{
	.container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
		max-width: 1640px;
	}
}
h1 {
    color: $white-color;
    font-size: 50px;
    font-family: $font-stack-1;
    line-height: 60px;  
    @media (max-width:1600px){
        font-size: 40px;
        line-height: 53px;   
    }
    @include media-breakpoint-down(xxl){  
        font-size: 35px;
        line-height: 38px;  
    }
    @include media-breakpoint-down(lg){  
        font-size: 22px;
        line-height: 33px;  
    }
    html[dir="rtl"] &{
        font-family: $font-stack-6;   
    } 
}
h2 {
    font-size: 30px;
    font-family: $font-stack-3;
    line-height: 30px;
    color: $primary-color;
    @media (max-width:1600px){  
        font-size: 26px;
        line-height: 20px;
     } 
     @include media-breakpoint-down(md){  
        font-size: 22px;
    }
    html[dir="rtl"] &{
        font-family: $font-stack-6;   
    } 
}
h3 {
    font-family: $font-stack-1;
    font-size: 22px;
    line-height: 27px;
    color: $secondary-color;
    @media (max-width:1600px){  
        font-size: 20px;
        line-height: 25px;
     }
    @include media-breakpoint-down(md){  
        font-size: 20px;
    }
    html[dir="rtl"] &{
        font-family: $font-stack-6;   
    } 
}

p {
    font-size: 18px;
    @media (max-width:1600px){
        font-size:16px;
    }
    @include media-breakpoint-down(md){  
        font-size: 14px;
    }
}
