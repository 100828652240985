.footer {
    background-color: $secondary-color;
    padding: 25px 0;
    @include media-breakpoint-down(md){  
        padding: 20px 0; 
    }
    .footer-logo {
        img {
            width: 180px;
            padding-block-end: 25px;
            @include media-breakpoint-down(md){  
                width: 140px;
                padding-block-end: 15px;
            }
        }
    }
    p {
        color: #ffffff8c;    
    }
    .footer-link {
        font-family: $font-stack-3;
        color: $white-color;
        font-size: 20px;   
        margin-block-end: 25px;
            @include media-breakpoint-down(md){  
                font-size: 17px;
                margin-block-end: 18px;
            }
            html[dir="rtl"] &{
                font-family: $font-stack-6;   
            } 
        }   
    }
.footer-menu {
    li {
        a {
            font-family: $font-stack-4;
            color: #ffffff8c;
            font-size: 16px;   
            &:hover {
                text-decoration: underline;
            }
            @include media-breakpoint-down(md){  
                font-size: 14px;
            }
        }
    }
}
.footer-social a {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px 30px 0;
    color: #ffffff;
    border-radius: 20px;
    transition: all 0.3s;
        &:hover {
            transition: all 0.3s;
            background: $primary-color;
        }
    }
